import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';

export default function BaseLayout() {

	return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="100vh">
				<Outlet />
			</Box>
	);
}