import React, { useEffect, useReducer } from 'react'
import { AppContext } from './AppContext'
import AppReducer from './AppReducer'
import jwt from 'jwt-decode'

const State = (props) => {

	const initialState = {
		auth: null,
		user: null,
		companies: null,
		defaultValues: {
			vehicle: null,
        	depot: null,
			typeService: []
		}
	}

	const [state, dispatch] = useReducer(AppReducer, initialState, () => {
		// Persistent data
		const localData = sessionStorage.getItem('state')
		return localData ? JSON.parse(localData) : initialState
	})

	const setAuth = (bearerToken) => {
		sessionStorage.setItem('token', bearerToken);
		const auth = jwt(bearerToken);
		sessionStorage.setItem('companyID', auth.company_id);
		sessionStorage.setItem('userID', auth.user_uuid);
		dispatch({
			type: 'SET_AUTH',
			payload: auth
		})
	}

	const setUser = (data) => {
		dispatch({
			type: 'SET_USER',
			payload: data
		})
	}

	const setCompanies = (data) => {
		dispatch({
			type: 'SET_COMPANIES',
			payload: data
		})
	}

	const setDefaultVal = (data) => {
		dispatch({
			type: 'SET_DEFAULT',
			payload: data
		})
	}

	useEffect(() => {
		sessionStorage.setItem('state', JSON.stringify(state))
	}, [state])

	return (
		<AppContext.Provider value={{
			auth: state.auth,
			user: state.user,
			companies: state.companies,
			defaultVal: state.defaultValues,
			setUser,
			setCompanies,
			setAuth,
			setDefaultVal
		}} >
			{props.children}
		</AppContext.Provider >
	)
}

export default State
