import axios from 'axios'
import i18n from 'i18next';

const HTTP = {
	init() {
		axios.defaults.baseURL = process.env.REACT_APP_API_URL
	},
	setDefaultHeaders() {
		axios.defaults.headers.common['Authorization'] = `JWT ${sessionStorage.getItem('token')}`
		axios.defaults.headers['company'] = sessionStorage.getItem('companyID')
		axios.defaults.headers['Content-Type'] = 'application/json'
		axios.defaults.headers['language'] = i18n.language
		axios.defaults.responseType = 'json'
	},
	setHeadersLogin() {
		axios.defaults.headers.common['Authorization'] = 'null'
	},
	setHeaderBlob() {
		this.setDefaultHeaders()
		axios.defaults.responseType = 'blob'
	},
	setFileHeaders() {
		this.setDefaultHeaders()
		axios.defaults.headers['Accept'] = 'application/json'
		axios.defaults.headers['Content-Type'] = 'multipart/form-data'
	},
	getArchive(resource, params) {
		this.setDefaultHeaders()
		return axios({
			url: resource,
			params: params,
			method: 'GET',
			responseType: 'blob' // important
		})
	},
	all(requests) {
		return axios.all(requests)
	},
	get(resource, slug) {
		this.setDefaultHeaders()
		return axios.get(resource + (slug ? '/' + slug : ''))
	},
	getWithQuery(resource, params) {
		this.setDefaultHeaders()
		return axios.get(resource, { params })
	},
	getBlob(resource) {
		this.setHeaderBlob()
		return axios.get(resource)
	},
	getBlobWithParams(resource, params) {
		this.setHeaderBlob()
		return axios.get(resource, { params })
	},
	post(resource, params, isLogin, isFileHeaders) {
		if (isLogin) {
			this.setHeadersLogin()
		} else {
			if (isFileHeaders) {
				this.setFileHeaders()
			} else {
				this.setDefaultHeaders()
			}
		}
		return axios.post(resource, params)
	},
	postBlob(resource, params) {
		this.setHeaderBlob()
		return axios.post(resource, params)
	},
	put(resource, params) {
		this.setDefaultHeaders()
		return axios.put(resource, params)
	},
	delete(resource, params) {
		this.setDefaultHeaders()
		return axios.delete(resource, params)
	},
	deleteWithBody(resource, data) {
		this.setDefaultHeaders()
		return axios.delete(resource, { data })
	},
	patch(resource, params) {
		this.setDefaultHeaders()
		return axios.patch(resource, params)
	},
	update(resource, slug, params) {
		return axios.put(`${resource}/${slug}`, params)
	},
	getSocketWithQuery(resource, params) {
		this.setDefaultHeaders()
		const url = process.env.VUE_APP_URL_SOCKET + resource
		return axios.get(url, { params })
	},
	getSocket(resource) {
		this.setDefaultHeaders()
		const url = process.env.VUE_APP_URL_SOCKET + resource
		return axios.get(url)
	},
	postSocket(resource, params) {
		this.setDefaultHeaders()
		const url = process.env.VUE_APP_URL_SOCKET + resource
		return axios.post(url, params)
	}
}

export default HTTP
