import React, { useContext, useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Avatar, Divider, ListItemIcon, ListItemText } from '@mui/material';
import { AccountCircle, PersonAdd, Logout, Settings, Badge } from '@mui/icons-material';
import MoreIcon from '@mui/icons-material/MoreVert';
import logo from '../assets/images/logo/logo.png';
import { AppContext } from '../context/AppContext';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

export default function PrimarySearchAppBar() {
	const { user } = useContext(AppContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const { t } = useTranslation();

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	  };
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		sessionStorage.clear()
		window.location = '/'
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar color='secondary'>
				<Toolbar>
					<Box sx={{ flexGrow: 1 }}>
						<img src={logo} alt='logo smartquick' width='40px' />
					</Box>
					<div style={{textAlign: 'right'}}>
						<Typography 
							variant="subtitle2"
							component="div"
							sx={{ display: { xs: 'none', sm: 'block' } }}
						>
							{user.company.name}
						</Typography>
						<Typography
							variant="caption"
							component="div"
							sx={{ display: { xs: 'none', sm: 'block' } }}
						> 
							{user.company.current_role.name}
						</Typography>
					</div>
					<IconButton 
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenu}
						color="inherit"
					>
						<Avatar sx={{ display: { xs: 'none', sm: 'block' } }}/>
						<MoreIcon sx={{ display: { xs: 'block', sm: 'none' } }}/>
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem>
							<ListItemIcon>
								<AccountCircle fontSize="small" />
							</ListItemIcon>
							<ListItemText 
								primary={user.full_name.slice(0,45)}
								secondary={
									user.company.name.length > 25 ? user.company.name.slice(0,25)+'... - '+user.company.current_role.name
									: user.company.name+' - '+user.company.current_role.name
								}
								sx={{ display: { xs: 'block', sm: 'none' } }}
							/>
							<ListItemText 
								primary={user.full_name.slice(0,45)}
								sx={{ display: { xs: 'none', sm: 'block' } }}
							/>
						</MenuItem>
						<Divider />
						<MenuItem component={Link} to="/dashboard/companies">
							<ListItemIcon>
								<PersonAdd fontSize="small" />
							</ListItemIcon>
							{t('drawer.companies')}
						</MenuItem>
						<MenuItem component={Link} to="/dashboard/services">
							<ListItemIcon>
								<Settings fontSize="small" />
							</ListItemIcon>
							{t('drawer.services')}
						</MenuItem>
						<MenuItem component={Link} to="/dashboard/default">
							<ListItemIcon>
								<Badge fontSize="small" />
							</ListItemIcon>
							{t('drawer.default')}
						</MenuItem>
						<MenuItem onClick={() => handleLogout()}>
							<ListItemIcon>
								<Logout fontSize="small" />
							</ListItemIcon>
							{t('btn.logout')}
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
		</Box>
	);
}
