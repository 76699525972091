import React from 'react';
import LoginForm from '../components/LoginForm'
import { Grid } from '@mui/material';


export default function Login() {

	return (
		<>
			<Grid
				container
				direction="row"
				justifyContent="center"
			>
				<Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
					<LoginForm />
				</Grid>
			</Grid>

		</>
	)
}