import React, { useState, useContext, Fragment } from 'react';
import { TextField, Button, Grid, CardMedia, Menu, MenuItem, Box, Container } from '@mui/material';
import logo from '../assets/images/logo/logo_total.png'
import {
	GoogleReCaptchaProvider,
	GoogleReCaptcha,
	useGoogleReCaptcha
} from "react-google-recaptcha-v3";
import { login, getUserData } from '../api/Login'
import { toast } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { LoadingButton } from '@mui/lab';
import TranslateIcon from '@mui/icons-material/Translate';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const keyCaptcha = process.env.REACT_APP_RECAPTCHA_KEY

function LoginForm() {
	const { register, handleSubmit, formState: { errors } } = useForm();

	const { executeRecaptcha } = useGoogleReCaptcha();
	const { setAuth, setUser, setCompanies, setDefaultVal } = useContext(AppContext)
	let navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [loading, setLoading] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const changeLanguage = lng => {
		i18n.changeLanguage(lng);
		setAnchorEl(null);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const submit = async (data) => {
		setLoading(true)
		try {
			const token = await executeRecaptcha('login');
			login({
				email: data.email,
				password: data.password,
				token: token
			}).then(res => {
				setAuth(res.data.token)
				getUser()
			}).catch(err => {
				setLoading(false)
				toast.error(t('session.error'))
			})
		} catch (e) {
			toast.error(t('error'))
			setLoading(false)
		}
	}

	const getUser = () => {
		getUserData(sessionStorage.getItem('userID')).then(res => {
			const data = { ...res.data };
			setCompanies(data.companies)
			if(data.company.web_config.default_values_pwa) {
				const { vehicle, depot, type_service } = data.company.web_config.default_values_pwa
				setDefaultVal({ 
					vehicle: JSON.stringify(vehicle) === '{}' ? null : vehicle,
					depot: JSON.stringify(vehicle) === '{}' ? null : depot,
					typeService: type_service
				})
			}
			delete data.companies
			setUser(data)
			toast.success(t('session.success'))
			setLoading(false)
			navigate('/dashboard/services');
		}).catch(err => {
			setLoading(false)
			toast.error(t('error'))
		})
	}

	return (
		<Fragment>
			<Container component="main" maxWidth="xs">
				<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', justifyContent:'center'}}>
					<CardMedia
						component="img"
						image={logo}
						alt="primary smartquick logo"
					/>
					<Box component="form" onSubmit={handleSubmit(submit)} noValidate sx={{ mt: 1 }}>
						<TextField
							required
							fullWidth
							error={errors.email ? true : false}
							id="email"
							label={t('field.email')}
							name="email"
							type="email"
							autoComplete="email"
							color='secondary'
							autoFocus
							{...register("email", {required: true})}
							helperText={errors.email ? t('errors.required') : ''}
						/>
						<TextField 
							margin="normal"
							required
							fullWidth
							error={errors.password ? true : false}
							name="password"
							label={t('field.password')}
							type="password"
							id="password"
							autoComplete="current-password"
							color='secondary'
							{...register("password", {required: true})}
							helperText={errors.password ? t('errors.required') : ''}
						/>
						<LoadingButton
							loading={loading}
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							{t('btn.login')}
						</LoadingButton>
					</Box>
					<Grid container>
						<Grid item sx={{ m: 0 }} style={{ marginLeft: 'auto' }}>
							<Button
								id="demo-positioned-button"
								aria-controls="demo-positioned-menu"
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleClick}
								variant="text"
								startIcon={<TranslateIcon />}
							>
								{t('btn.language')}
							</Button>
							<Menu
								id="demo-positioned-menu"
								aria-labelledby="demo-positioned-button"
								anchorEl={anchorEl}
								open={open}
								onClose={() => setAnchorEl(null)}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
							>
								<MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
								<MenuItem onClick={() => changeLanguage('es')}>Español</MenuItem>
							</Menu>
						</Grid>
					</Grid>
				</Box>
			</Container>
			<GoogleReCaptcha />
		</Fragment>
	);
}

const Recaptcha = () => {
	const { i18n } = useTranslation();
	return (
		<GoogleReCaptchaProvider
			language={i18n.language}
			reCaptchaKey={keyCaptcha}
		>
			<LoginForm />
		</GoogleReCaptchaProvider>
	);
};

export default Recaptcha
