import { TextField, FormControl, InputLabel, Select, MenuItem, FormLabel, FormGroup, Stack, Typography, Switch, FormHelperText } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const StringField = (props) => {
    const { t } = useTranslation();
    return (
        <TextField 
            fullWidth
            color="secondary"
            label={props.data.labelName}
            value={props.data.value}
            onChange={e => props.data.event(props.data.index, e.target.value) }
            error={props.error ? true : false}
			helperText={props.error ? t(`errors.additional.${props.error.type}`) : ''}
        />
    );
};
const EmailField = (props) => {
    const { t } = useTranslation();
    return (
        <TextField 
            fullWidth
            color="secondary"
            label={props.data.labelName}
            value={props.data.value}
            onChange={e => props.data.event(props.data.index, e.target.value) }
            error={props.error ? true : false}
			helperText={props.error ? t(`errors.additional.${props.error.type}`) : ''}
        />
    );
};
const SelectField = (props) => {
    const { t } = useTranslation();
    return (
        <FormControl fullWidth error={props.error ? true : false}>
            <InputLabel color="secondary" id={props.data.labelName}>{props.data.labelName}</InputLabel>
            <Select
                labelId={props.data.labelName}
                label={props.data.labelName}
                value={props.data.value}
                defaultValue=""
                onChange={e => props.data.event(props.data.index, e.target.value) }
            >
                {
                    props.data.options.map(item => {
                        return <MenuItem key={item.id} value={item.value}>{item.key}</MenuItem>
                    })
                }
            </Select>
            <FormHelperText>{props.error ? t(`errors.additional.${props.error.type}`) : ''}</FormHelperText>
        </FormControl>
    );
};
const BooleanField = (props) => {
    const { t } = useTranslation();
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{props.data.labelName}</FormLabel>
            <FormGroup>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{t('not')}</Typography>
                <Switch 
                    color="secondary"
                    checked={props.data.value}
                    onChange={e => props.data.event(props.data.index, e.target.checked) }
                />
                <Typography>{t('yes')}</Typography>
            </Stack>
            </FormGroup>
        </FormControl>
    );
};

const DateField = (props) => {
    const { t } = useTranslation();
    let dateObj;
    
    if (typeof props.data.value === 'string') {
        const [year, month, day] = props.data.value.split("-").map(Number);
        dateObj = new Date(year, month - 1, day);
    } else {
        dateObj = props.data.value; 
    }

    const handleDateChange = (e) => {
        if (e){
            const dateString = `${e.getFullYear()}-${String(e.getMonth() + 1).padStart(2, '0')}-${String(e.getDate()).padStart(2, '0')}`;
            return props.data.event(props.data.index, dateString); 
        }
        else {
            return props.data.event(props.data.index, ''); 
        }
    };

    return (
        <FormControl fullWidth error={props.error ? true : false}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label={props.data.labelName}
                    value={dateObj}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    error={props.error ? true : false}
                />
            </LocalizationProvider>
            <FormHelperText>{props.error ? t(`errors.additional.${props.error.type}`) : ''}</FormHelperText>
        </FormControl>
    );
}

export default function AdditionalFields(props) {
    const type = props.type;
    switch(type) {
        case 'string':
        case 'url':
        case 'int':
        case 'float':
        case 'email':
            return <EmailField data={props.data} error={props.error}/>
        case 'barcode':
            return <StringField data={props.data} error={props.error}/>;
        case 'select':
        case 'radio':
            return <SelectField data={props.data} error={props.error}/>;
        case 'boolean':
            return <BooleanField data={props.data}/>;
        case 'date':
            return <DateField data={props.data} error={props.error} />;
        default:
            return null;
    };
};