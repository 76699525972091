import HTTP from "../../http-common";

const apiService = {
	getSelectDepot() {
		let url = `/depots/?sort=name&is_seller=false&is_active=true&by_workgroup=true`;
		return HTTP.get(url);
	},
	getVehicle() {
		return HTTP.get('/vehicles/?is_active=true&sort=plate');
	},
	predictionDocumentName(params) {
		return HTTP.getWithQuery(`/clients/search/`, params);
	},
	getPopulationCenter(data) {
		return HTTP.get(`/cities/search/?filter=?${data}`);
	},
	getsServiceAddress(code, address) {
		return HTTP.get(`geo/?city=${code}&address=${address}`);
	},
	getDocumentType() {
		return HTTP.get('/companies/config/document_types/');
	},
	recoveryPassword(data) {
		return HTTP.post('/rest-auth/password/reset/', data, true);
	},
	getTypeService() {
		return HTTP.get('/products/types');
	},
	getBaseFields() {
		return HTTP.get('/companies/config/base_fields/services');
	},
	getCubingVariables() {
		return HTTP.get('/companies/config/web/cubing/');
	},
	getAdditionalFields() {
		return HTTP.get('/companies/dynamic_fields/?module=services');
	},
	newService(data) {
	  	return HTTP.post('/services/create', data)
	}
};

export default apiService;
