import HTTP from "../../http-common"

export function login(data) {
	return HTTP.post("users/login/", data, true)
}

export function getUserData(userId) {
	return HTTP.get(`users/info/${userId}`)
}

export function recoveryPassword(data) {
	return HTTP.post('/rest-auth/password/reset/', data, true)
}

export function logout() {
	HTTP.post('rest-auth/logout/', null, true)
}
